
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";

export default defineComponent({
  name: "jerrycanFilters",
  props: ["applied_filters"],
  data() {
    return {
      daterange: "",
      filters: this.applied_filters,
      transportList: [],
    };
  },
  emits: ["changed"],
  methods: {
    dataChanged() {
      this.$emit("changed", this.filters);
    },
    getTransports() {
      ApiService.query("index/transports", {}).then((response) => {
        this.transportList = response.data;
      });
    },
  },
  mounted() {
    this.getTransports();
  },
});
